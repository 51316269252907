export function getAllSteps(stepList) {
  const steps = [];
  function getList(list) {
    list.forEach((step, index) => {
      if (step.type !== "ROUTER" && step?.steps?.length === 0) {
        steps.push({
          // eslint-disable-next-line radix
          index: step?.position?.x || index,
          position: step?.position || undefined,
          conditionPosition: step?.conditionPosition || undefined,
          ...step,
        });
      }

      if (step.steps) {
        getList(step.steps);
      }
      if (step.routes) {
        getList(step.routes);
      }
    });
  }

  getList(stepList);

  return steps;
}
